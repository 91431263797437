function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

declare global {
  interface Window {
    MarnieConfig: MarnieConfig;
  }
}

class MarnieConfig {
  // Whether or not we are in production environment.
  isProd: boolean;
  // Config state
  configState: "LOADED" | "LOADING" | "NOTLOADED" = "NOTLOADED";

  ingestBucket: string = "";
  outputBucket: string = "";
  apiEndpoint: string = "";
  identityPoolId: string = "";
  region: string = "";
  userPoolId: string = "";
  userPoolWebClientId: string = "";

  constructor() {
    this.isProd = process.env.NODE_ENV === "production";
    this.loadConfig();
  }

  loadConfig = () => {
    if (this.configState === "NOTLOADED") {
      const endpoint = `https://${window.location.host}/config.json`;
      console.log(`Loading config values from ${endpoint}`);
      this.configState = "LOADING";

      return fetch(endpoint)
        .then((response) => response.json())
        .then((data) => {
          this.ingestBucket = data.ingestBucket;
          this.outputBucket = data.outputBucket;
          this.apiEndpoint = data.apiEndpoint;
          this.identityPoolId = data.identityPoolId;
          this.region = data.region;
          this.userPoolId = data.userPoolId;
          this.userPoolWebClientId = data.userPoolWebClientId;
          this.configState = "LOADED";
          //console.log("Config values loaded", this);
        })
        .catch((error) => {
          console.error(
            `Error loading config.json. Tried to fetch ${endpoint}. Error was: ${error}`
          );
        });
    }
  };

  async waitForConfigToLoad() {
    if (this.configState === "NOTLOADED") {
      this.loadConfig();
    }
    let loopCount = 0;
    while (this.configState !== "LOADED" && loopCount < 300) {
      await sleep(100);
    }
    if (this.configState !== "LOADED") {
      console.error("Unable to load config values");
    }
  }

  async getIngestBucket(): Promise<string> {
    await this.waitForConfigToLoad();
    return this.ingestBucket;
  }
}

export { MarnieConfig };
