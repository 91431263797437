import { DateTime } from "luxon";

type LeaderUsername =
  | "anapmm"
  | "ctod"
  | "gregdunc"
  | "katemcna"
  | "matpayto"
  | "mgreens"
  | "schwcind";

type ScheduleItem = {
  date: number;
  username: LeaderUsername;
  reportId: string;
};
// See https://quip-amazon.com/GHZuAepCdQj2/Marshal-Report-Inspection
class SendSchedule {
  static schedule: ScheduleItem[] = [
    { date: 20240509, username: "mgreens", reportId: "39960" },
    { date: 20240516, username: "gregdunc", reportId: "39961" },
    { date: 20240523, username: "schwcind", reportId: "39962" },
    { date: 20240530, username: "matpayto", reportId: "39963" },
    { date: 20240606, username: "gregdunc", reportId: "43885" },
    { date: 20240613, username: "schwcind", reportId: "43887" },
    { date: 20240620, username: "mgreens", reportId: "43888" },
    { date: 20240627, username: "gregdunc", reportId: "43889" },
    { date: 20240704, username: "schwcind", reportId: "" },
    { date: 20240711, username: "matpayto", reportId: "" },
    { date: 20240718, username: "ctod", reportId: "45669" },
    { date: 20240725, username: "katemcna", reportId: "45670" },
    { date: 20240801, username: "mgreens", reportId: "45671" },
    { date: 20240808, username: "gregdunc", reportId: "45672" },
    { date: 20240815, username: "schwcind", reportId: "45673" },
    { date: 20240822, username: "mgreens", reportId: "45674" },
    { date: 20240829, username: "gregdunc", reportId: "45675" },
    { date: 20240905, username: "katemcna", reportId: "48020" },
    { date: 20240912, username: "gregdunc", reportId: "48021" },
    { date: 20240919, username: "mgreens", reportId: "48022" },
    { date: 20240926, username: "ctod", reportId: "48023" },
    { date: 20241024, username: "mgreens", reportId: "49830" },
    { date: 20241031, username: "gregdunc", reportId: "49831" },
  ];

  /**
   * Get the next sender.
   *
   * @param offset Get the `offset`th record. For example:
   *               - passing 1 would get the sender the week after this one.
   *               - Passing -2 would get the sender 2 weeks before.
   * @returns ScheduleItem with next sender (or an offset of that.)
   */
  static getNextSender(offset: number = 0): ScheduleItem {
    const date = this.todayDate();
    for (let i = 0; i < this.schedule.length; i++) {
      if (this.schedule[i].date >= date) {
        return this.schedule[i + offset];
      }
    }
    return { date: 20240101, username: "anapmm", reportId: "" };
  }

  /**
   * @returns A date as a YYYYMMDD number.
   */
  static todayDate(): number {
    const d = new Date();
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");
    return parseInt(`${d.getFullYear()}${month}${day}`);
  }

  /**
   * Converts a YYYYMMDD number to a Luxon DateTime object.
   */
  static parseDate = (dateNumber: number): DateTime => {
    const dateString = String(dateNumber);
    const year = parseInt(dateString.slice(0, 4));
    const month = parseInt(dateString.slice(4, 6));
    const day = parseInt(dateString.slice(6, 8));
    return DateTime.local(year, month, day);
  };
}
export { SendSchedule };
export type { ScheduleItem };
