import { API, Auth } from "aws-amplify";

type RequestParams = {
  headers?: {}; //optional
  response?: boolean;
  queryStringParameters?: {
    [key: string]: string;
  };
};

type QueryParams = {
  [key: string]: string;
};

class MarnieApiClient {
  readonly basePath = "fileapi";
  readonly apiName = "MarnieFileApi";

  async get(action: string, params?: QueryParams) {
    const requestParams: RequestParams = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      response: true,
      queryStringParameters: {
        ...params,
        ...{
          action: action,
        },
      },
    };

    const response = await API.get(this.apiName, this.basePath, requestParams);
    console.log("Response from API", response);
    return response.data.response;
  }

  async getUploadUrl(
    filename: string,
    email: string,
    template = "anzo",
    headerImage = "header-generic.png"
  ) {
    const action = "getSignedUrl";
    const responseData = await this.get(action, {
      fileName: filename,
      uploaderEmail: email || "",
      template: template.toLowerCase(),
      headerImage: headerImage,
    });
    return responseData.uploadUrl;
  }

  // Implement a singleton pattern so we have one client for the whole app.
  //   public static getInstance() {
  //     if (!MarnieApiClient.instance) {
  //       MarnieApiClient.instance = new MarnieApiClient();
  //     }
  //     return MarnieApiClient.instance;
  //   }
}

export { MarnieApiClient };
