import "./Footer.css";
import { madeWithLoveImage } from "./lib/made-with-love";
// Design stolen from https://cloudscape.design/examples/react/cards.html
const Footer = () => {
  return (
    <div className="marnie-footer">
      <ul>
        <li>
          A Project of{" "}
          <a href="https://w.amazon.com/bin/view/AWS/Teams/WWPS/SA/ANZ">
            WWPS ANZO SA
          </a>
        </li>
        <li>
          Production account: <code>846260193565</code>
        </li>
        <li>
          Dev account: <code>853843566408</code>
        </li>
        <li>
          <a href="https://gitlab.aws.dev/aws-wwps-apj-sa/marshal-newsletter">
            Gitlab
          </a>
        </li>
        <li>
          Need more deets?{" "}
          <a href="mailto:aws-wwps-anz-sa-weekly-report-admins@amazon.com">
            Email us
          </a>{" "}
          or ctod@
        </li>
      </ul>
      <div dangerouslySetInnerHTML={{ __html: madeWithLoveImage }}></div>
    </div>
  );
};

export { Footer };
