import { HelpPanel, Icon } from "@cloudscape-design/components";

const ToolsHelp = () => {
  return (
    <HelpPanel
      header={<h2>Generate newsletter</h2>}
      footer={
        <div>
          <h3>
            Learn more <Icon name="external" />
          </h3>
          <ul>
            <li>
              <a href="https://marshal.corp.amazon.com/reports#{%22filters%22:{%22operation%22:%22and%22,%22tokens%22:[{%22propertyKey%22:{%22model%22:%22report%22,%22attributes%22:[%22name%22,%22description%22]},%22propertyLabel%22:%22Tags%22,%22value%22:%22ANZO%22,%22label%22:%22ANZO%22,%22negated%22:false},{%22propertyKey%22:{%22model%22:%22report%22,%22attributes%22:[%22name%22,%22description%22]},%22propertyLabel%22:%22Tags%22,%22value%22:%22WWPS%22,%22label%22:%22WWPS%22,%22negated%22:false},{%22propertyKey%22:{%22model%22:%22report%22,%22attributes%22:[%22name%22,%22description%22]},%22propertyLabel%22:%22Tags%22,%22value%22:%22SOLUTIONS%20ARCHITECTS%22,%22label%22:%22SOLUTIONS%20ARCHITECTS%22,%22negated%22:false}]},%22sort%22:{%22column%22:%22resource_id%22,%22direction%22:%22desc%22}}">
                Marshal Reports
              </a>
            </li>
            <li>
              <a href="https://quip-amazon.com/GHZuAepCdQj2/Marshal-Report-Inspection-2023">
                Quip tracking doc
              </a>
            </li>
          </ul>
        </div>
      }
    >
      <div>
        <p>
          This will generate an HTML table suitable for using as an email
          newsletter. It works by using an exported <code>.csv</code> report
          from Marshal, parsing it to turn it into a table, then emailing it to
          you.
        </p>
        <p>
          To get started, find a report in Marshal, and click
          <code>Export</code>. Choose the CSV format, and you will download a
          file with a name like <code>2023-05-18_RPT-27955.csv</code>. Upload
          that file here, and you will receive an email with a formatted report
          suitable for sharing.
        </p>
        <hr />
        <dl>
          <dt>
            What does <em>"Generic (by category)"</em> and{" "}
            <em>"Generic (by report section)"</em> mean?
          </dt>
          <dd>
            For reports without a specific template, you can choose to organise
            your insights into groups by one of two fields.
            <ul>
              <li>
                <strong>Category</strong>, defined as{" "}
                <code>insightCategory</code> in the data. This is a required tag
                against each Insight, with a value like "Call Out", "Key
                Business Trend", "Customer Highlight", and so on. Insights are
                guaranteed to have this field.
              </li>
              <li>
                <strong>Report section</strong>, defined as{" "}
                <code>reportSection</code> in the data. These are sections
                arbitrarily created by the report author, and can be any text -
                for example, in the case of ASEAN report{" "}
                <a href="https://marshal.corp.amazon.com/report/45260">45260</a>{" "}
                "Innovation through Generative AI", "Improve Security &
                Resilience", and so on.
              </li>
            </ul>
          </dd>
          <dt>Why are some Insights not showing in my report?</dt>
          <dd>
            The most likely reason is that you do not have access to these
            Insights, so when they appear in the data with all their field
            values changed to "Access Denied". Check your CSV file to make sure.
            If they are in the CSV but not in your report, please contact us via
            the details in the footer of this site.
          </dd>
        </dl>
      </div>
    </HelpPanel>
  );
};

export { ToolsHelp };
