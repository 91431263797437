import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { MarnieConfig } from "./util";

const config = new MarnieConfig();
config.waitForConfigToLoad().then(() => {
  console.log("config", config);
  Amplify.configure({
    Auth: {
      identityPoolId: config.identityPoolId,
      region: config.region,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.userPoolWebClientId,
    },
    API: {
      endpoints: [{ name: "MarnieFileApi", endpoint: config.apiEndpoint }],
    },
  });

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <Authenticator hideSignUp={true}>
        {({ signOut, user }) => (
          <>
            <App />
          </>
        )}
      </Authenticator>
    </React.StrictMode>
  );
});
