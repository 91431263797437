import {
  FilePicker,
  Footer,
  LeaderSchedule,
  PageHeader,
  ToolsHelp,
} from "./components";
import { useState } from "react";
import "@cloudscape-design/global-styles/index.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  AppLayout,
  ContentLayout,
  SpaceBetween,
} from "@cloudscape-design/components";

function App() {
  const toolWidth = window.innerWidth / 3;
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [showTools, setShowTools] = useState(true);
  const email = user.attributes?.email || "ctod@amazon.com";

  return (
    <>
      <AppLayout
        contentType="form"
        content={
          <ContentLayout
            header={
              <PageHeader
                headerText="🐕 Generate Marshal Newsletter Emails"
                signOutFunction={signOut}
              />
            }
          >
            <SpaceBetween size="l">
              <div className="App">
                <FilePicker userEmail={email} />
              </div>
              <LeaderSchedule />
            </SpaceBetween>
          </ContentLayout>
        }
        tools={<ToolsHelp />}
        toolsOpen={showTools}
        onToolsChange={() => setShowTools(!showTools)}
        toolsWidth={toolWidth}
        navigationHide={true}
        footerSelector=".marnie-footer"
      />

      <Footer />
    </>
  );
}

export default App;
