import { Box, Button, Grid } from "@cloudscape-design/components";

const PageHeader = ({
  headerText,
  signOutFunction,
}: {
  headerText: string;
  signOutFunction: Function;
}): JSX.Element => {
  return (
    <Grid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
      <Box variant="h1" margin={{ top: "l", left: "s" }}>
        {headerText}
      </Box>
      <Box margin={{ top: "xxl", left: "xxl" }}>
        <Button onClick={() => signOutFunction}>Sign Out</Button>
      </Box>
    </Grid>
  );
};

export { PageHeader };
