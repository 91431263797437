import Flashbar from "@cloudscape-design/components/flashbar";

// Show a status message for the user's upload.
// Visibility and variant are managed in App.tsx and informed by util/FileUpload
const messages = {
  success: "File uploaded! Check your email for a generated report. 🎉",
  uploading: "Uploading...",
  error: "Something went wrong. Please try again :(",
};
type MessageKey = keyof typeof messages;

const UploadProgress = ({ status }: { status: MessageKey }) => {
  return (
    <Flashbar
      items={[
        {
          type: status === "error" ? "error" : "success",
          loading: status === "uploading" ? true : false,
          content: messages[status as MessageKey],
          id: "message_1",
        },
      ]}
    />
  );
};

export { UploadProgress };
export type { MessageKey };
