import * as React from "react";
import { FormField, Select } from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

const HEADER_IMAGES = [
  "header-apj-tech.png",
  "header-apj-industry.png",
  "header-anzo.png",
  "header-asean.png",
  "header-generic.png",
  "header-enterprise-support-anz.png"
];
const HEADER_DEFAULT = "header-asean.png";

interface HeaderImagePickerProps {
  setSelectedTemplateStringFunction: Function;
}

const HeaderImagePicker = (props: HeaderImagePickerProps) => {
  const [selectedHeader, setSelectedHeader] = React.useState<OptionDefinition>({
    label: HEADER_DEFAULT,
    value: HEADER_DEFAULT,
  });

  const doSetSelectedHeader = (selected: OptionDefinition) => {
    setSelectedHeader(selected);
    // Pass the value of our selected form field up to our encapsulating component, so we can include it in the form submission.
    props.setSelectedTemplateStringFunction(selected.value);
    console.log(selected);
  };

  const headerOptions = HEADER_IMAGES.map((image) => ({
    label: image,
    value: image,
  }));

  return (
    <FormField
      label="Header Image"
      description="The header image used in the top of the email. Only selectable for the 'Generic' templates."
    >
      <Select
        selectedOption={selectedHeader}
        onChange={({ detail }) => doSetSelectedHeader(detail.selectedOption)}
        options={headerOptions}
      />
      <img
        src={"https://newsletter.anz.wwps.aws.dev/" + selectedHeader.value}
        alt={selectedHeader.label}
        width="100%"
      />
    </FormField>
  );
};

export { HeaderImagePicker };
