import {
  Box,
  Button,
  Container,
  FileUpload,
  FormField,
  Grid,
  Header,
  Select,
} from "@cloudscape-design/components";

import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

import { Fragment, useState } from "react";
import { MessageKey, UploadProgress } from "./UploadProgress";
import { HeaderImagePicker } from "./HeaderImagePicker";

import { MarnieApiClient } from "../util/MarnieRestClient";

interface FilePickerProps {
  userEmail: string;
}
const FilePicker = (props: FilePickerProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadEnabled, setUploadEnabled] = useState<boolean>(false);
  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const [uploadStatus, setUploadStatus] = useState<MessageKey>("uploading");
  const [uploadProgressVisible, setUploadProgressVisible] =
    useState<boolean>(false);
  const [headerPickerVisible, setheaderPickerVisible] =
    useState<boolean>(false);

  const [selectedHeaderString, setSelectedHeaderString] =
    useState<string>("header-generic.png");

  const defaultSelectedTemplate = {
    label: "ANZO",
    value: "anzo",
  };

  const [selectedTemplate, setSelectedTemplate] = useState<OptionDefinition>(
    defaultSelectedTemplate
  );

  const doSetSelectedTemplate = (selectedTemplate: OptionDefinition) => {
    setSelectedTemplate(selectedTemplate);

    let visible = headerPickerVisible;
    if (selectedTemplate.value !== undefined) {
      visible = ["by_reportsection", "by_category"].includes(
        selectedTemplate.value
      );
    }
    setheaderPickerVisible(visible);
  };

  const fileChosen = (files: File[]) => {
    setFiles(files);
    // This is the case when the 'dismiss' button is clicked.
    if (files.length === 0) {
      setUploadProgressVisible(false);
      setFileErrors([]);
      setUploadEnabled(false);
      console.log("length 0");
      return;
    } else if (files[0].type !== "text/csv") {
      // The file upload widget only allows 1 file to be uploaded.
      setFileErrors(["Only CSV files are allowed"]);
      setUploadEnabled(false);
      return;
    }
    setUploadEnabled(true);
  };

  const getUploadUrl = async (): Promise<string> => {
    const api = new MarnieApiClient();
    const uploadUrl = await api.getUploadUrl(
      files[0].name,
      props.userEmail,
      selectedTemplate.value || defaultSelectedTemplate.value,
      selectedHeaderString || "header-generic.png"
    );
    return uploadUrl;
  };

  const uploadFile = async () => {
    setUploadStatus("uploading");
    setUploadProgressVisible(true);
    const presignedUrl = await getUploadUrl();
    const result = await fetch(presignedUrl, {
      method: "PUT",
      body: files[0],
    });
    if (result.status !== 200) {
      setUploadStatus("error");
      return;
    }
    setUploadStatus("success");
    console.log(result);
  };

  // Wrapper for UploadProgress which only shows progress on file submit.
  const Progress = () => {
    if (uploadProgressVisible) {
      return <UploadProgress status={uploadStatus} />;
    }
    return <></>;
  };

  return (
    <Container>
      <Header>Welcome to Marnie!</Header>
      <p>
        This app will create an emailable newsletter from a Marshal report.
        Export your report as a <code>.csv</code> file from Marshal, upload it
        here, and you will be emailed a draft report. Links to Marshal reports
        are below.
      </p>

      <Grid
        gridDefinition={[
          { colspan: { default: 4, xxs: 4 } },
          { colspan: { default: 8, xxs: 8 } },
        ]}
      >
        <div id="column-1">
          <FormField
            description={
              <Fragment>
                Upload a .csv export from&nbsp;
                <a href="https://marshal.corp.amazon.com/">Marshal</a>
              </Fragment>
            }
            label="Exported CSV file"
          >
            <FileUpload
              onChange={({ detail }) => fileChosen(detail.value)}
              value={files}
              i18nStrings={{
                uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                dropzoneText: (e) =>
                  e ? "Drop files to upload" : "Drop file to upload",
                removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                limitShowFewer: "Show fewer files",
                limitShowMore: "Show more files",
                errorIconAriaLabel: "Error",
              }}
              fileErrors={fileErrors}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={1}
            />
            <Progress />
          </FormField>
        </div>
        <div id="column-2">
          {" "}
          <FormField
            description="Email template to apply to this report"
            label="Email Report Template"
          >
            <Select
              onChange={({ detail }) =>
                doSetSelectedTemplate(detail.selectedOption)
              }
              selectedOption={selectedTemplate}
              options={[
                { label: "ANZO", value: "anzo" },
                { label: "APJ Industry", value: "apj_industry" },
                { label: "APJ Tech", value: "apj_tech" },
                { label: "ASEAN", value: "asean" },
                { label: "Generic (by category)", value: "by_category" },
                {
                  label: "Generic (by report section)",
                  value: "by_reportsection",
                },
              ]}
            />
          </FormField>
          {headerPickerVisible && (
            <HeaderImagePicker
              setSelectedTemplateStringFunction={setSelectedHeaderString}
            />
          )}
        </div>
      </Grid>
      <Box margin={{ top: "xxl" }}>
        <Button disabled={!uploadEnabled} onClick={() => uploadFile()}>
          Upload CSV
        </Button>
      </Box>
    </Container>
  );
};

export { FilePicker };
