import { SendSchedule, ScheduleItem } from "./lib/SendSchedule";
import { DateTime } from "luxon";
import {
  Box,
  Container,
  Grid,
  Header,
  Link,
  Table,
} from "@cloudscape-design/components";

const LeaderSchedule = () => {
  // Use Flicking library?

  // const columnDefinitions = [
  //   { id: "date", header: "Date", cell: (e) => e.date },
  //   { id: "username", header: "Leader", cell: (e) => e.userDetails },
  //   { id: "report", header: "Report", cell: (e) => e.reportLink },
  // ];

  const ValueWithLabel = ({
    label,
    children,
  }: {
    label: JSX.Element | string;
    children: JSX.Element | string;
  }) => (
    <div>
      <Box variant="strong">{label}</Box>: <Box variant="span">{children}</Box>
    </div>
  );

  function readableDate(d: DateTime) {
    return d.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
  }

  function marshalReportLink(reportId: number | string) {
    if (reportId !== "") {
      const marshalLink = `https://marshal.corp.amazon.com/report/${reportId}`;
      return (
        <Link href={marshalLink} target="_blank">
          {marshalLink}
        </Link>
      );
    } else {
      return "No report yet!";
    }
  }

  function phonetoolLink(username: string) {
    return (
      <Link
        target="_blank"
        href={`https://phonetool.amazon.com/users/${username}`}
      >
        {username}
      </Link>
    );
  }

  function ScheduleItem({
    username,
    date,
    reportId,
  }: {
    username: string;
    date: number;
    reportId: string;
  }) {
    const d = SendSchedule.parseDate(date);
    return (
      <Container>
        <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
          <Box>
            <img
              src={`https://newsletter.anz.wwps.aws.dev/badges/badge-${username}.jpg`}
              alt={username}
            />
          </Box>
          <Box>
            <ValueWithLabel label="Sender" children={phonetoolLink(username)} />
            <ValueWithLabel label="Date" children={readableDate(d)} />

            <ValueWithLabel
              label="Report"
              children={marshalReportLink(reportId)}
            />
          </Box>
        </Grid>
      </Container>
    );
  }

  function ScheduleTable() {
    const items = [];

    const nowDateTime = DateTime.local();
    for (const item of SendSchedule.schedule) {
      const itemDateTime = SendSchedule.parseDate(item.date);
      // Include last week and everything after in our table.
      if (itemDateTime.weekNumber < nowDateTime.weekNumber - 1) {
        continue;
      }
      items.push({
        date: readableDate(SendSchedule.parseDate(item.date)),
        username: phonetoolLink(item.username),
        report: marshalReportLink(item.reportId),
      });
    }

    return (
      <Table
        columnDefinitions={[
          {
            id: "date",
            header: "Date",
            cell: (item) => item.date,
          },
          {
            id: "username",
            header: "Who",
            cell: (item) => item.username,
          },
          {
            id: "reportId",
            header: "Report",
            cell: (item) => item.report,
          },
        ]}
        items={items}
      />
    );
  }

  const nextSender = SendSchedule.getNextSender();
  const [username, date, reportId] = [
    nextSender.username,
    nextSender.date,
    nextSender.reportId || "",
  ];

  let nextWeekSender: ScheduleItem | null | undefined = null;
  nextWeekSender ??= SendSchedule.getNextSender(1);
  let [nextUser, nextDate, nextReportId] = ["", 1999, ""];
  if (nextWeekSender) {
    [nextUser, nextDate, nextReportId] = [
      nextWeekSender.username,
      nextWeekSender.date,
      nextWeekSender.reportId || "",
    ];
  }

  return (
    <div>
      <Container
        header={
          <Header headingTagOverride="h2">Newsletter Sender Schedule</Header>
        }
      >
        <h2>This week</h2>
        <ScheduleItem username={username} date={date} reportId={reportId} />
        <h2>Next week</h2>
        <ScheduleItem
          username={nextUser}
          date={nextDate}
          reportId={nextReportId}
        />
        <br />
        <hr style={{ color: "#eee" }} />
        <h2>Full Schedule</h2>
        <ScheduleTable />
      </Container>
    </div>
  );
};

export { LeaderSchedule };
